import setting from '../config/setting.js'
import Vue from 'vue'

export default {
    login(data) {
        localStorage.setItem(setting.userToken, JSON.stringify(data))
    },
    // authentication status
    authenticated() {
        var t = localStorage.getItem(setting.userToken);
        return t && t.length > 0;
    },
    getToken() {
        let josn = localStorage.getItem(setting.userToken)
        try {
            return josn ? JSON.parse(josn) : '';
        } catch (error) {
            console.error('解析 userToken 失败:', error)
            return {}
        }
        
    },
    delToken() {
        localStorage.removeItem(setting.userToken)
    },
    logout() {
        localStorage.setItem(setting.userToken, "");
    }
}
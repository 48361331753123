<template>
    <el-dialog
        :close-on-click-modal="false"
        :visible.sync="show"
        align-center
        class="payino-dialog"
        width="450px"
        @close="close()"
    >
        <div class="payinfo-form">
            <div class="modal">
                <div class="form">
                    <div class="separator">
                        <hr class="line">
                        <p>pay using credit card</p>
                        <hr class="line">
                    </div>
                    <div class="credit-card-info--form">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <!-- 持卡人名 -->
                                <div class="input_container">
                                    <label class="input_label" for="full_name">THE FIRST NAME</label>
                                    <input id="full_name" v-model="formData.firstName" class="input_field"
                                           name="input-name" placeholder="Enter your first name" title="Inpit title"
                                           type="text">
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <!-- 持卡人姓 -->
                                <div class="input_container">
                                    <label class="input_label" for="full_name">THE LAST NAME</label>
                                    <input id="full_name" v-model="formData.lastName" class="input_field"
                                           name="input-name" placeholder="Enter your last name" title="Inpit title"
                                           type="text">
                                </div>
                            </el-col>
                        </el-row>
                        <!-- 持卡人电话 -->
                        <div class="input_container">
                            <label class="input_label" for="card_number">THE MOBILE</label>
                            <input id="card_number" v-model="formData.phone" class="input_field"
                                   name="input-name"
                                   placeholder="Enter your phone number" title="Inpit title" type="number">
                        </div>
                        <!-- 国家代码简称 -->
                        <div class="input_container">
                            <label class="input_label" for="full_name">Country</label>
                            <el-select v-model="formData.country" placeholder="Please select">
                                <el-option
                                    v-for="item in countryData"
                                    :key="item.altSpellings"
                                    :label="item.name_en"
                                    :value="item.altSpellings">
                                </el-option>
                            </el-select>
                        </div>
                        <!-- 签收洲 -->
                        <div class="input_container">
                            <label class="input_label" for="full_name">Province</label>
                            <input id="full_name" v-model="formData.state" class="input_field"
                                    name="input-name" placeholder="Enter your state" title="Inpit title"
                                    type="text">
                        </div>
                        <!-- 城市 -->
                        <div class="input_container">
                            <label class="input_label" for="full_name">City</label>
                            <input id="full_name" v-model="formData.city" class="input_field"
                                    name="input-name" placeholder="Enter your city" title="Inpit title"
                                    type="text">
                        </div>
                        <!-- 详情地址 -->
                        <div class="input_container">
                            <label class="input_label" for="full_name">Address</label>
                            <input id="full_name" v-model="formData.address" class="input_field"
                                   name="input-name" placeholder="Enter your address" title="Inpit title" type="text">
                        </div>
                        <!-- 账单邮编 -->
                        <div class="input_container">
                            <label class="input_label" for="full_name">Zip code</label>
                            <input id="full_name" v-model="formData.zip" class="input_field" name="input-name"
                                   placeholder="Enter your zip" title="Inpit title" type="text">
                        </div>
                    </div>
                    <button class="purchase--btn" @click="confirm()">Checkout</button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
        show: {
            require: true,
            default: false
        }
    },
    data() {
        return {
            countryData: [], // 国家代码简称
            rules: [
                {key: 'firstName', errMsg: 'Please enter first name'},
                {key: 'lastName', errMsg: 'Please enter last name'},
                {key: 'phone', errMsg: 'Please enter phone'},
                {key: 'country', errMsg: 'Please select country'},
                {key: 'state', errMsg: 'Please enter state'},
                {key: 'city', errMsg: 'Please enter city'},
                {key: 'address', errMsg: 'Please enter address'},
                {key: 'zip', errMsg: 'Please enter zip'},
            ],
            formData: {
                firstName: '',
                lastName: '',
                phone: '',
                country: '',
                state: '',
                city: '',
                address: '',
                zip: '',
            },
        }
    },
    /**
     * Lifecycle hook that is called after the instance has been created.
     * It invokes the `getCountryCode` method to fetch the country code upon component creation.
     */
    created() {
        this.getCountryCode()
    },
    methods: {
        confirm() {
            for (let index = 0; index < this.rules.length; index++) {
                const rule = this.rules[index];
                if (this.formData[rule.key] === '') {
                    this.$message.error(rule.errMsg)
                    return
                }
            }
            this.$emit('confirm', this.formData)
        },
        getCountryCode() {
            this.$http.get('/config/getCountrys').then(res => {
                this.countryData = res.data
            })
        },
        close() {
            this.formData = this.$options.data().formData
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
<template>
  <div class="cart-wrapper">
    <!-- 其他内容 -->
    <div ref="scriptContainer"></div>
    <div class="categories-header real-presents__header">
      <div class="top-bar">
        <a class="back-btn" href="javascript:;" @click="$router.go(-1)">
          <div class="ui-icon ui-icon__display-inline" style="width: 24px; height: 24px;">
                        <span class="ui-icon__display-block" style="width: 24px; height: 24px;">
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd"
                                                                                              d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"
                                                                                              fill-rule="evenodd"></path></svg>
                        </span>
          </div>
          <p class="t-b-1 f-w-m hidden-xs-only"> Back to Profile </p>
        </a>
        <div class="caption">Choose a real gift for <span class="giftedUser">{{ nickname }}</span></div>
      </div>
    </div>
    <ul class="shopping-cart">
      <li class="cart-caption">
        Your order
      </li>
      <li
          v-for="(item, index) in cartData"
          :key="item.id"
          class="cart-li"
      >
        <el-row>
          <el-col :sm=12 :xs="24" style="display: flex; align-items: center;">
            <img :src="item.image | cdnurl" alt="">
            <span class="gift-name">
                            {{ item.name }}
                        </span>


          </el-col>
          <el-col :sm=12 :xs="24" style="display: flex; align-items: center;">
            <div class="gift-amount">

              <el-input-number v-model="item.num" :max="99" :min="1" size="medium"></el-input-number>

            </div>
            <span class="gift-money">
                                <i v-if="systemVersion === 'B' ||systemVersion === 'C' ||systemVersion === 'D'"
                                   class="unit">$</i>
                                {{ item.price * item.num }}
                                 <img
                                     v-if="systemVersion === 'A'||systemVersion === 'E'||systemVersion === 'F'||systemVersion === 'G'  "
                                     alt="credits"
                                     class="credits-icon"
                                     src="@/assets/svg/creditсoin.svg">
                            </span>
            <el-tag type="danger" @click="handleDeteleGift(index)"><i class="el-icon-delete"></i></el-tag>
          </el-col>
        </el-row>
      </li>
      <!-- 选择支付方式 start -->
      <li v-if="systemVersion === 'B' ||systemVersion === 'C' ||systemVersion === 'D'"
          class="payment-type-wrapper s-p-y-3 s-p-b-5">
        <ul v-if="cartData.length" class="payment-type-wrap">
          <li v-for="(row, index) in paymentTypes" :key="index" :class="{ checked: index === paymentIndex }"
              class="payment-type" @click="paymentIndex = index">
            <img :src="row.image | cdnurl" alt="security">
            <el-tag size="small">checked</el-tag>
          </li>
        </ul>
      </li>
      <!-- 选择支付方式 end -->
      <li class="total-amount">
        <p>Total:
          <i v-if="systemVersion === 'B' ||systemVersion === 'C' ||systemVersion === 'D'"
             class="unit">$</i>
          <span class="amount">{{ totalMoney }}</span>
          <img v-if="systemVersion === 'A'||systemVersion === 'E'||systemVersion === 'F'||systemVersion === 'G' "
               alt="credits" class="credits-icon"
               src="@/assets/svg/creditсoin.svg">
        </p>
      </li>
      <li class="pay-btn-wrapper">
        <el-button class="pay-btn" type="primary" @click="handleConfirmOrder">Submit</el-button>
      </li>
    </ul>

    <el-dialog
        :title="$t('Tip')"
        :visible.sync="centerDialogVisible"
        center
        width="30%">
      <span><i class="el-icon-present" style="color: #3B5998;"></i>{{ $t('GiftDelivered') }}</span>
      <span slot="footer" class="dialog-footer">
                <el-button plain type="primary" @click="chatNow">
                    <i class="el-icon-s-comment"></i> 
                    {{ $t('GoChat') }}
                </el-button>
                <el-button plain type="primary" @click="handleContinueSelectGift">
                    <i class="el-icon-present"></i> 
                    {{ $t('Continue') }}
                </el-button>
            </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :visible.sync="isShowCardInfoDialog" align-center class="payino-dialog"
               width="450px">
      <div class="payinfo-form">
        <div class="modal">
          <div class="form">
            <div class="separator">
              <hr class="line">
              <p>pay using credit card</p>
              <hr class="line">
            </div>
            <div class="credit-card-info--form">
              <div class="input_container">
                <label class="input_label" for="full_name">Card holder full name</label>
                <input id="full_name" v-model="cardInfo.name" class="input_field" name="input-name"
                       placeholder="Enter your full name" title="Inpit title" type="text">
              </div>
              <div class="input_container">
                <label class="input_label" for="card_number">Card Number</label>
                <input id="card_number" v-model="cardInfo.cardnum" class="input_field" name="input-name"
                       placeholder="0000 0000 0000 0000" title="Inpit title" type="number">
              </div>
              <div class="input_container">
                <label class="input_label" for="expire_date">Expiry Date / CVV</label>
                <div class="split">
                  <input id="expire_date" v-model="cardInfo.date" class="input_field"
                         name="input-name"
                         placeholder="01/23" title="Expiry Date" type="text">
                  <input id="cvv" v-model="cardInfo.cvc" class="input_field" name="cvc"
                         placeholder="CVC"
                         title="CVC" type="number">
                </div>
              </div>
            </div>
            <button class="purchase--btn" @click="goPay()">Checkout</button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :visible.sync="isShowCropayInfoDialog" align-center
               class="payino-dialog"
               width="450px">
      <div class="payinfo-form">
        <div class="modal">
          <div class="form">
            <div class="separator">
              <hr class="line">
              <p>pay using credit card</p>
              <hr class="line">
            </div>
            <div class="credit-card-info--form">
              <div class="input_container">
                <label class="input_label" for="full_name">Card holder full name</label>
                <input id="full_name" v-model="cropayInfo.name" class="input_field" name="input-name"
                       placeholder="Enter your full name" title="Inpit title" type="text">
              </div>
              <div class="input_container">
                <label class="input_label" for="card_number">Card holder phone number</label>
                <input id="card_number" v-model="cropayInfo.phone" class="input_field" name="input-name"
                       placeholder="Enter your phone number" title="Inpit title" type="number">
              </div>
            </div>
            <button class="purchase--btn" @click="goPay()">Checkout</button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex';
import {Toast, Dialog} from 'vant';
import i18n from "@/lang";

export default {
  data() {
    return {
      userId: '',
      nickname: '',
      centerDialogVisible: false,

      paymentTypes: [], // 支付方式
      paymentIndex: -1, // 选中的支付方式的下标
      isShowCardInfoDialog: false,
      isShowCropayInfoDialog: false,
      cardInfo: {
        name: '',
        cardnum: '',
        date: '',
        cvc: '',
      },
      extends: {
        guid: "",
        ip: "",
        url_referrer_domain: ""
      },
      cropayInfo: {
        name: '',
        phone: '',
      },
    }
  },
  computed: {
    ...mapState({
      cartData: 'cartData'
    }),
    ...mapGetters({
      systemVersion: 'getSystemVersion'
    }),
    totalMoney() {
      let amount = 0
      this.cartData.forEach(item => {
        amount += item.num * item.price
      })
      return amount
    }
  },
  created() {
    this.userId = this.$route.query.to_uid
    this.nickname = this.$route.query.nickname
    this.getPaymentTypes()
  },
  methods: {
    ...mapMutations([
      'deleteCartData',
      'clearCartData'
    ]),
    handleConfirmOrder() {
      if (!this.cartData.length) {
        return Toast('Please choose the gift first!')
      }
      Dialog.confirm({
        title: '',
        message: i18n.t("ConfirmOrder"),
      }).then(() => {
        if (this.systemVersion === 'A' || this.systemVersion === 'E' || this.systemVersion === 'F' || this.systemVersion === 'G') {
          return this.goPay()
        }
        if (this.paymentTypes[this.paymentIndex].type === 'custom') {
          this.isShowCardInfoDialog = true
        } else if (this.paymentTypes[this.paymentIndex].type === 'cropay1' || this.paymentTypes[this.paymentIndex].type === 'cropay2') {
          this.isShowCropayInfoDialog = true
        } else {
          this.goPay()
        }
      }).catch(() => {
        // on cancel
      });
    },
    handleDeteleGift(index) {
      this.deleteCartData(index)
    },
    chatNow() {
      this.$router.push({
        path: '/message',
        query: {
          id: this.userId
        }
      })
    },
    handleContinueSelectGift() {
      this.centerDialogVisible = false
      this.$router.go(-1)
    },
    /**
     * 下单支付
     */
    goPay() {
      this.isShowCardInfoDialog = false
      let paytype = this.paymentTypes[this.paymentIndex]?.type || 'money'
      let memo = ''
      if (paytype === 'custom') {
        if (this.cardInfo.name === '') return this.$message.warning('Please enter card holder full name')
        if (this.cardInfo.cardnum === '') return this.$message.warning('Please enter card number')
        if (this.cardInfo.date === '') return this.$message.warning('Please enter expiry date')
        if (this.cardInfo.cvv === '') return this.$message.warning('Please enter CVV')
        memo = JSON.stringify(this.cardInfo)
      }
      if (paytype === 'cropay1' || paytype === 'cropay2') {
        if (this.cropayInfo.name === '') return this.$message.warning('Please enter card holder full name')
        if (this.cropayInfo.phone === '') return this.$message.warning('Please enter card holder phone number')
        memo = JSON.stringify(this.cropayInfo)
      }
      const checkedGifts = this.cartData.map(item => {
        return {
          id: item.id,
          num: item.num
        }
      })
      this.$http.post('/mall/pay', {
        paytype,
        to_user_id: this.userId,
        gifts: checkedGifts,
        memo: memo,
        extends: this.extends,
      })
          .then(res => {
            // console.log(res)
            // return
            if (res.code == 1) {
              if (!res.data.payUrl) {
                this.centerDialogVisible = true
                this.clearCartData()
              } else {
                window.open(res.data.payUrl, '_self')
              }
            } else {
              this.$message.error(res.msg)
            }
          })
    },
    /**
     * 获取支付方式
     */
    getPaymentTypes() {
      this.$http.post('/credits/getPayTypeList')
          .then(res => {
            if (res.code === 1) {
              this.paymentTypes = res.data.list
              this.extends = res.data.extends
              this.writeScript()
            }
          })
    },
    writeScript() {
      const script = document.createElement('script');
      let guid = this.extends.guid;
      let url_referrer_domain = this.extends.url_referrer_domain;
      let ip = this.extends.ip;

      script.src = 'https://www.wshtmltool.com/order_bill.js?guid=' + guid + '&language=en-us&ip=' + ip + '&url_referrer_domain=' + url_referrer_domain;
      script.onload = () => {
        console.log('External script loaded.');
        // 执行其他逻辑
      };
      this.$refs.scriptContainer.appendChild(script);
    }
  }
}
</script>

<style lang="scss" scoped>
.giftedUser {
  font-size: 26px;
  color: #b86800;
}

.cart-wrapper {

  .categories-header {

    .top-bar {
      display: flex;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #d6d6d6;
      background-color: #fff;
      color: #333;

      .back-btn {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333;;
      }

      .caption {
        flex: 1;
        text-align: center;
        font-size: 18px;
      }
    }
  }

  .shopping-cart {
    padding: 20px;
    background-color: #fff;

    .cart-caption {
      font-size: 20px;
      font-weight: 700;
    }

    .cart-li {
      display: flex;
      align-items: center;
      padding: 20px 0;
      border-bottom: 1px solid #ccc;

      &:last-of-type {
        border-bottom: 0;
      }

      img {
        width: 80px;
        height: 80px;
      }

      .gift-name {
        flex: 1;
        padding: 0 20px;
      }

      .gift-amount {
        flex: 1;
        display: flex;
      }

      .gift-amount .unit {
        font-style: normal;
      }

      .gift-price {
        display: flex;
        align-items: flex-end;
        padding: 0 20px;
        font-size: 20px;
      }

      .gift-price .credits-icon {
        width: 20px;
        height: auto;
        margin-left: 2px;
      }

      .el-input {
        width: 80px;
        text-align: center;
        font-size: 20px;
      }

      .gift-money {
        display: flex;
        align-items: flex-end;
        padding: 0 20px;
        font-size: 20px;
      }

      .gift-money .credits-icon {
        width: 20px;
        height: auto;
        margin-left: 2px;
      }

      .gift-money .unit {
        margin-right: 3px;
      }

      .el-tag {
        cursor: pointer;
      }
    }

    .total-amount {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
    }

    .total-amount .credits-icon {
      width: 20px;
      height: auto;
      margin-left: 2px;
    }

    .total-amount .amount {
      font-size: 20px;
    }

    .total-amount .unit {
      font-size: 20px;
      font-style: normal;
      margin-right: 3px;
    }
  }
}

.payment-type-wrapper {
  border-bottom: 1px solid #ccc;

  .payment-type-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    list-style: none;


    .payment-type {
      overflow: visible;
      position: relative;
      box-sizing: border-box;
      flex: 1;
      border-radius: 20px;
      cursor: pointer;
      transition: 0.3s ease-out;
      max-width: 200px;
      padding: 10px;
      display: flex;
      align-items: center;

      &:hover {
        box-shadow: 0 0 10px 2px var(--primaryColor);
      }

      &.checked {
        box-shadow: 0 0 10px 2px var(--primaryColor);

        .el-tag {
          transform: translate(-50%, 50%);
          opacity: 1;
        }
      }

      img {
        width: 100%;
        border-radius: 20px;
      }

      .el-tag {
        position: absolute;
        left: 50%;
        bottom: 0;
        border-radius: 1rem;
        border: none;
        background-color: var(--primaryColor);
        color: #fff;
        opacity: 0;
        transform: translate(-50%, 125%);
        transition: 0.3s ease-out;
      }
    }
  }
}

.payino-dialog {
  ::v-deep(.el-dialog) {
    background-color: transparent;

    .el-dialog__close {
      font-size: 28px;
    }
  }
}

.payinfo-form {
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    width: fit-content;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01), 0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 26px;
    max-width: 450px;

    .form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;

      .separator {
        width: calc(100% - 20px);
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        gap: 10px;
        color: #8B8E98;
        margin: 0 10px;

        p {
          display: block;
          white-space: nowrap;
          text-align: center;
          font-weight: 600;
          font-size: 16px;
          margin: auto;
          color: #333;
        }

        .line {
          display: inline-block;
          width: 100%;
          height: 1px;
          border: 0;
          background-color: #e8e8e8;
          margin: auto;
        }
      }

      .credit-card-info--form {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .input_container {
          width: 100%;
          height: fit-content;
          display: flex;
          flex-direction: column;
          gap: 5px;

          .input_label {
            font-size: 12px;
            color: #8B8E98;
            font-weight: 600;
          }

          .input_field {
            box-sizing: border-box;
            width: auto;
            height: 40px;
            padding: 0 0 0 16px;
            border-radius: 9px;
            outline: none;
            background-color: #F2F2F2;
            border: 1px solid #e5e5e500;
            transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);

            &:focus {
              border: 1px solid transparent;
              box-shadow: 0px 0px 0px 2px #3B5998;
              background-color: transparent;
            }
          }

          .split {
            display: grid;
            grid-template-columns: 4fr 2fr;
            gap: 15px;

            input {
              width: 100%;
            }
          }
        }
      }

      .purchase--btn {
        height: 55px;
        background: #F2F2F2;
        border-radius: 11px;
        border: 0;
        outline: none;
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
        background: linear-gradient(180deg, #5a6d98 0%, #2049a0 50%, #3B5998 100%);
        box-shadow: 0px 0px 0px 0px #FFFFFF, 0px 0px 0px 0px #3B5998;
        transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);

        &:hover {
          box-shadow: 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #3B59983a;
        }
      }
    }
  }
}

/* Reset input number styles */
.input_field::-webkit-outer-spin-button,
.input_field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input_field[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 767px) {
  .shopping-cart {
    padding-bottom: 70px;

    .pay-btn {
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
@media only screen and (max-width: 767px) {
  .payment-message-box {
    width: 80vw;
  }
}
</style>
<template>
  <el-row>
    <div v-if="Object.keys(userinfo).length > 0">
      <el-col :lg="14" :md="14" :sm="24" :xs="24">
        <div v-if="Object.keys(userinfo).length > 0" class="userinfo">
          <el-avatar :size="90" :src="userinfo.avatar | cdnurl" shape="square"></el-avatar>
          <span class="username" @click="profileVisible = true">
                        {{ userinfo.nickname }}
                    </span>

          <span class="group">
                        ID : {{ userinfo.id }}
            <!-- <el-image style="width: 20px; height: 20px" :src="userinfo.UserGroup.image"></el-image>
            {{ userinfo.UserGroup.name }} -->
                        <span class="primary-color" style="cursor: pointer;"> <i
                            class="icon-al-jinbi"></i>  {{ userinfo.money }}</span>
                    </span>

          <span class="vip">
                        <span v-if="userinfo.userDetail && userinfo.userDetail.vip_expire_time > 0">
                            <i :class="{ 'icon-al-VIP': true, 'primary-color': userinfo.userDetail.vip_expire_time > new Date().getTime() / 1000 }"
                               style="font-size: 25px;"></i>
                            <el-link :underline="false" style="margin-left: 10px;margin-bottom:10px;" type="info">
                                {{ userinfo.userDetail.vip_expire_time | expiredTime }}
                                ({{ $t('RenewNow') }})
                            </el-link></span>
                        <span v-else>
                            <el-link :underline="false" style="padding-bottom: 3px;" type="info">{{
                                $t('notVIP')
                              }}</el-link>
                        </span>
                    </span>
        </div>
      </el-col>
      <el-col :lg="10" :md="10" :sm="24" :xs="24" class="heder-right">
        <el-col :lg="8" :md="8" :sm="8" :xs="8" class="heder-right-item header-border">
          <p>
            <el-link :underline="false" type="primary" @click="openFollowDialog('ilike')">
              {{ userinfo.ilike_num }}
            </el-link>
          </p>
          <p class="heder-right-text">{{ $t('Iliked') }}</p>
        </el-col>
        <el-col :lg="8" :md="8" :sm="8" :xs="8" class="heder-right-item header-border">
          <p>
            <el-link :underline="false" type="warning" @click="openFollowDialog('likeme')">
              {{ userinfo.like_me_num }}
            </el-link>
          </p>
          <p class="heder-right-text">{{ $t('LikeMe') }}</p>
        </el-col>
        <el-col :lg="8" :md="8" :sm="8" :xs="8" class="heder-right-item ">
          <p>
            <el-link :underline="false" type="success" @click="openFollowDialog('each')">{{
                userinfo.like_each_other_num
              }}
            </el-link>
          </p>
          <p class="heder-right-text">{{ $t('LikeEachOther') }}</p>
        </el-col>

      </el-col>

    </div>
    <el-dialog :destroy-on-close="true" :title="$t('dataList')" :visible.sync="followVisible"
               custom-class="follow-dialog"
               width="50%">
      <el-row ref="scrollRef">
        <carList :list="followList"></carList>
      </el-row>
      <el-col :lg="24" :md="24" :sm="24" :xs="24" class="pagination">
        <el-pagination :current-page="searchData.page" :hide-on-single-page="true" :page-size="searchData.limit"
                       :total="total"
                       background layout="prev, pager, next"
                       @current-change="changePage">
        </el-pagination>
      </el-col>
      <el-empty v-if="followList.length == 0" :description="$t('DataIsEmpty')"></el-empty>
    </el-dialog>

    <!-- <el-dialog :title="$t('PersonalInformation')" :close-on-click-modal="false" top="10px"
        :visible.sync="profileVisible" width="50%">
        <profile :userinfo="userinfo" @updateUserInfoSuccess="updateUserInfoSuccess"></profile>
    </el-dialog> -->

  </el-row>
</template>

<script>
import carList from "../member/cardList.vue"
import profile from "./profile.vue"

export default {
  name: 'userinfo',
  components: {carList, profile},
  props: {},
  data: function () {
    return {
      followList: [],
      followVisible: false,
      profileVisible: false,
      searchData: {
        follow_type: "ilike",
        page: 1,
        limit: 12,
      },
      total: 0,
      userinfo: {}
    }
  },
  methods: {
    getUserInfo() {
      let that = this;
      that.$http.post('/user/userinfo')
          .then((res) => {
            if (res.code == 1) {
              that.userinfo = res.data
            } else {
              that.$message.error(res.msg);
            }

          })

    },
    openFollowDialog(type) {

      let that = this;
      that.searchData.follow_type = type
      that.getFollowList()
      this.followVisible = true
    },
    getFollowList() {
      let that = this;
      that.$http.get('/user/getList', {params: that.searchData})
          .then((res) => {
            if (res.code == 1) {
              this.followList = res.data.list || [];
              this.total = res.data.total;
              this.loading = false
            } else {
              that.$message.error(res.msg);
            }

          })
    },
    changePage: function (page) {
      this.searchData.page = page;
      this.getFollowList();
      // 回到顶部
      this.$refs.scrollRef.scrollTop = 0;
    },
    updateUserInfoSuccess() {

      this.getUserInfo()
      this.profileVisible = false
    }
  },
  created() {
    this.getUserInfo()

    setTimeout(() => {
      let query = this.$route.query;
      this.profileVisible = query.profile == 1
    }, 200);
  },

}
</script>

<style scoped>
.userinfo {
  width: 100%;
  height: 100px;
}

.userinfo .el-avatar {
  float: left;
}

.userinfo .username {
  display: block;
  color: #606266;
  font-size: 26px;
  margin-left: 110px !important;
  font-weight: bold;
  padding-top: 10px;
}

.userinfo .username a {
  color: #606266;
  font-size: 26px;

}

.userinfo .group {
  display: block;
  color: #606266;
  font-size: 14px;
  margin-left: 110px !important;
  line-height: 25px;
  justify-content: center;
  height: 25px;
  margin-top: 15px;
}

.userinfo .vip {
  display: block;
  color: #606266;
  font-size: 12px;
  margin-left: 110px;
  line-height: 20px;
  margin-top: 5px;
}

.vip .el-link {
  font-size: 12px !important;
}

.heder-right {
  text-align: center;
  color: #606266;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heder-right-text {
  font-size: 14px;
}

.header-border {
  border-right: 1px solid #e7e7e7;
}

.heder-right-item {
  padding: 5px;

}

</style>
<style>
@media only screen and (max-width: 767px) {
  .follow-dialog {
    width: 90% !important;
  }
}
</style>